import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import {
  Observable,
  combineLatest,
  filter,
  map,
  shareReplay,
  startWith,
} from 'rxjs';
import { LoggedInUser } from 'src/app/models/logged-in-user.model';
import { UserLoggedOut } from 'src/app/state/user/user.action';
import { UserState } from 'src/app/state/user/user.state';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  @Select(UserState.user)
  user$!: Observable<LoggedInUser>;

  groups$: Observable<Group[]>;

  private groups: Group[] = [
    {
      active: false,
      icon: 'content_copy',
      text: 'Content',
      routes: [
        {
          key: 'category',
          icon: 'category',
          text: 'Categories',
          url: 'categories',
          active: false,
          allowForScopped: true,
        },
        {
          key: 'question',
          icon: 'quiz',
          text: 'Questions',
          url: 'questions',
          active: false,
          allowForScopped: true,
        },
        {
          key: 'golden-points',
          icon: 'lightbulb',
          text: 'Golden Points',
          url: 'golden-points',
          active: false,
          allowForScopped: true,
        },
        {
          key: 'user-report',
          icon: 'flag',
          text: 'User Reports',
          url: 'user-reports',
          active: false,
          allowForScopped: false,
        },
        {
          key: 'question-report',
          icon: 'flag',
          text: 'Question Reports',
          url: 'question-reports',
          active: false,
          allowForScopped: true,
        },
      ],
    },
    {
      icon: 'group',
      active: false,
      text: 'Users Management',
      routes: [
        {
          active: false,
          icon: 'person',
          key: 'users',
          text: 'Users',
          url: 'users',
          allowForScopped: false,
        },
        {
          active: false,
          icon: 'admin_panel_settings',
          key: 'admins',
          text: 'Admins',
          url: 'users/admins',
          allowForScopped: false,
        },
        {
          active: false,
          icon: 'workspaces',
          key: 'packages',
          text: 'Packages',
          url: 'packages',
          allowForScopped: false,
        },
        {
          active: false,
          icon: 'vpn_key',
          key: 'codes',
          text: 'Codes',
          url: 'codes',
          allowForScopped: false,
        },
      ],
    },
    {
      active: false,
      icon: 'campaign',
      text: 'Engagement',
      routes: [
        {
          key: 'notifications',
          icon: 'notifications',
          text: 'Notifications',
          url: 'notifications',
          active: false,
          allowForScopped: false,
        },
      ],
    },
  ];

  private activeLink$: Observable<string | null>;

  @Select(UserState.isScopped)
  isScopped$!: Observable<boolean>;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly location: Location
  ) {
    this.activeLink$ = router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event) => event.url),
      startWith(this.router.url),
      map((url) => {
        const baseUrl = url.split('?')[0];
        const routes = this.groups.flatMap((group) => group.routes);
        const route =
          // first try to match last piece
          routes.find((route) => baseUrl.endsWith(route.url))?.key ??
          // then if no match was found try to match any piece of the url
          routes.find((route) => baseUrl.includes(`/${route.url}/`))?.key ??
          null;

        return route;
      })
    );

    this.groups$ = this.buildRoutesStream(this.activeLink$, this.isScopped$);
  }

  trackByKey(_: any, route: Route) {
    return route.key;
  }

  onLogout() {
    this.store.dispatch(new UserLoggedOut());
    this.router.navigate(['/auth/signin']);
  }

  onBack() {
    this.location.back();
  }

  private buildRoutesStream(
    activeLinkStream: Observable<string | null>,
    isScoppedStream: Observable<boolean>
  ): Observable<Group[]> {
    return combineLatest([activeLinkStream, isScoppedStream]).pipe(
      map(([activeLink, isScopped]) =>
        this.groups
          .map((group) => {
            const routes = group.routes
              .map((route) => ({
                ...route,
                active: activeLink === route.key,
              }))
              .filter((route) => (isScopped ? route.allowForScopped : true));

            return {
              ...group,
              active: routes.some((route) => route.active),
              routes: routes,
            };
          })
          .filter((group) => group.routes.length > 0)
      ),
      shareReplay(1)
    );
  }
}

interface Group {
  active: boolean;
  icon: string;
  text: string;
  routes: Route[];
}

interface Route {
  key: string;
  icon: string;
  url: string;
  text: string;
  active: boolean;
  allowForScopped: boolean;
}
